import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import { Button,Modal } from 'antd';

import userpic from '../../image/user1.png';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'


const { logout } = authAction;

class TopbarLogout extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    //console.log('NO!');
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  renderModal(){
    return (
      <Modal visible={this.state.visible} width={500} footer={null} 
        onCancel={() => {
          this.setState({ visible: false });
        }} closeIcon={<div />}
        bodyStyle={{
          backgroundColor: 'black',
          border: '1px solid #3266a8',
          boxShadow: '0px 0px 8px 0px #3266a8',
          padding: 30
        }}>
        <p style={{ textAlign: "center", color: '#faf6f5', fontWeight: 'bold', fontSize: 22,marginTop:-10 }}>{`ต้องการออกจากระบบหรือไม่`}</p>
        <div style={{ flexDirection: 'row', display: 'flex', marginTop: 20 }} >
            <Button type="primary" loading={this.state.loading} onClick={this.props.logout} block style={{ marginRight: 8, backgroundColor: 'black', borderColor: '#28a745', color: '#28a745', height: 38 }}>
              {'Yes'}
            </Button>
            <Button type="primary" onClick={this.hide} block style={{ marginLeft: 8, backgroundColor: 'black', borderColor: '#dc3545', color: '#dc3545', height: 38 }}>
              {'No'}
            </Button>
          </div>
      </Modal>
    )
  }

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <a className="isoDropdownLink" href="">
          <IntlMessages id="themeSwitcher.settings" />
        </a>
        <a className="isoDropdownLink" href="">
          <IntlMessages id="sidebar.feedback" />
        </a>
        <a className="isoDropdownLink" href="">
          <IntlMessages id="topbar.help" />
        </a>
        <a className="isoDropdownLink" onClick={this.props.logout} href="">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
    //   <Popover
    //     content={content}
    //     trigger="click"
    //     visible={this.state.visible}
    //     onVisibleChange={this.handleVisibleChange}
    //     arrowPointAtCenter={true}
    //     placement="bottomLeft"
    //   >
    //     <div className="isoImgWrapper">
    //       <img alt="user" src={userpic} />
    //       <span className="userActivity online" />
    //     </div>
    //   </Popover>
      <Button 
        style={{marginTop:18,color:this.props.color||'#f9d276',fontWeight: 'bold'}}
        type="link"
        onClick={this.handleVisibleChange}>
        <FontAwesomeIcon icon={faPowerOff} size="lg" />
        {this.renderModal()}
      </Button>
    );
  }
}
export default connect(
  null,
  { logout }
)(TopbarLogout);
